.App {
  text-align: center;
  font-family: 'Axiforma', sans-serif !important;
  overflow-x: hidden;
  zoom: 0.8;

}

:root{
  --primary:#F9C81A,
  --secondary:#006132,
}
.App-logo {
  height: 40vh;
  pointer-events: none;
}
.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #006132;
  --bs-btn-border-color: #006132;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #006132;
  --bs-btn-hover-border-color: #006132;
  --bs-btn-focus-shadow-rgb: 60,153,110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #006132;
  --bs-btn-active-border-color: #006132;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #006132;
  --bs-btn-disabled-border-color: #006132;
}
.btn-warning {
    --bs-btn-color: #000;
    --bs-btn-bg: #F9C81A;
    --bs-btn-border-color: #F9C81A;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #F9C81A;
    --bs-btn-hover-border-color: #F9C81A;
    --bs-btn-focus-shadow-rgb: 217,164,6;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #F9C81A;
    --bs-btn-active-border-color: #F9C81A;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #F9C81A;
    --bs-btn-disabled-border-color: #F9C81A;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {

    border-radius: 1px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #F9C81A;
    border-radius: 1px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #F9C81A;
  }