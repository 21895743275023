.propertyManagement {
  .topRowDisplay {
    h2 {
      color: #006132;
    }

    p {
      padding-inline: 15rem;
      color: gray;
      font-size: 1.3rem;

      @media screen and (max-width: 650px) {
        padding-inline: 2rem;
        text-align: left;
      }
    }
  }


  .row {
    padding: 2rem;
    padding-inline: 6rem;
    margin-top: 3rem;

    @media screen and (max-width: 650px) {
      padding: 2rem;
      margin-top: 3rem;
      padding-inline: 0rem;
      display: flex;
      text-align: -webkit-center;
    }

    .col-hover {
      &:hover {
        i {
          border: 1px solid;
          padding: 1.5rem;
          border-radius: 50%;
          width: 30%;
          // height: 20vh;
          height: 17vh;

          place-self: center;
          margin-top: -3.6rem;
          z-index: 2;
          background: #006132;
          color: white;
          border-color: #006132;
          font-size: 3rem;
          cursor: pointer;

          @media screen and (max-width: 650px) {
            padding: 0.2rem;
            width: 25%;
            height: 10vh;
            font-size: 4rem;
          }
        }
      }
    }

    .card {
      height: 40vh;
      width: 25vw;
      margin-bottom: 0rem;

      @media screen and (max-width: 650px) {
        height: auto;
        width: 80vw;
        margin-bottom: 4rem;
      }

      i {
        border: 1px solid;
        padding: 1.5rem;
        border-radius: 50%;
        width: 30%;
        // height: 20vh;
        height: 17vh;

        place-self: center;
        margin-top: -3.6rem;
        z-index: 2;
        background: white;
        color: #F9C81A;
        border-color: #F9C81A;
        font-size: 3rem;

        @media screen and (max-width: 650px) {
          padding: 0.7rem;
          width: 25%;
          height: 10vh;
          font-size: 3rem;
      
        }
      }

      h2 {
        font-size: 1rem;
        color: #006132;
        margin-top: 1.5rem;

        @media screen and (max-width: 650px) {
          margin-top: 3rem;
        }
      }

      button {
        background-color: transparent !important;
        border-color: #006132;
        color: #006132;
        width: 15vw;
        align-self: center;
        margin-top: 2rem;
        margin-bottom: 0rem;

        @media screen and (max-width: 650px) {
          width: auto;
          margin-bottom: 2rem;
        }
      }
    }
  }
}