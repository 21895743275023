.footer {
    padding: 0 0 70px;
    background-color: #006132;
    color: white;
    text-align: left;


    h2 {
        font-size: 1rem;
    }

    p {
        font-size: 0.8rem;
        color: #F9C81A;
    }

    a {
        text-decoration: none;
        color: #F9C81A;
        font-size: 0.8rem
    }

    .lowerFooter {
        background-color: #006132;
        padding-bottom: 30px;
        padding-top: 40px;
        display: flex;
        position: absolute;
        justify-content: space-between;

        a {
            margin-inline: 1rem;
        }

        p {}
    }

    /* FooterAnimations.scss */

    .animated-text {
        opacity: 0;
        /* Initially hide the text */
        transform: translateY(20px);
        /* Move text down by 20px */
        transition: opacity 5s, transform 0.5s;
        /* Add transitions for opacity and transform */
    }

    .footer.show {
        opacity: 1;
        /* Show the text */
        transform: translateY(0);
        /* Reset transform */
    }


}

.footer_buttons {
    border-radius: 0.7rem;
    width: 38%;
    display: block;
    text-align: left;
    border: 1px solid lightgray;
}

.footer_buttons:hover,
.footer_buttons:active,
.footer_buttons:focus {
    border: 1px solid black !important;
}

.imageLogoFooter {
    width: 60%;

    @media screen and (max-width: 600px) {
        width: 30%;
    }
}