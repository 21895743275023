.coming-soon-page {
    height: 125vh;
    background-image: url('../images/property_sales.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  
  .content {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 40px;
    border-radius: 10px;
  }
  
  .logo {
    width: 150px;
  }
  

  