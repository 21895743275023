.blogsAll {
    .property {
        padding-top: 6rem;

        .topSelectedProperty {
            @media screen and (max-width: 600px) {
                margin-top: 0rem;
                margin-inline: 0rem;
                width: 100%;
            }

        }

        .housesCards .house {
            width: 30%;
            margin-inline: 14px;
            margin-bottom: 1.5rem;
            border: 1px solid #ccc;
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            @media screen and (max-width: 600px) {
             width: 100%;

            }
        }

        .cardProperty {
            box-shadow: rgba(17, 12, 46, 0.15) 2px 1px 0px 1px;
            padding: 0.5rem;
            margin-left: 5rem;
            margin-top: -4.5rem;
            height: 13vh;
            border-radius: 0;
            width: 88% !important;
            display: grid;
            grid-auto-flow: column;

            @media screen and (max-width: 600px) {
                margin-left: 2rem;
                .searchProperty {
                    padding: 1rem;
                    width: 19rem !important;
                    text-align: center;
                }

            }

            .searchProperty .form-control {
                width: 40rem !important;
                text-align: center;

                @media screen and (max-width: 600px) {
                    width: 12rem !important;

                }
            }

            select.formProperty.form-select.form-select-sm {
                height: 40px;
                margin-top: 1rem;
                width: 40%;
                margin-right: 0rem;
            }
        }
        .housesCards .house img {
            display: block;
            width: 100%;
            height: 13rem;
            border-radius: 10px;
            object-fit: cover;
        }
        .card-body {
           

            h2 {
                font-size: 1rem;
                font-weight: bold;
                color: #F9C81A;
                margin-top: 0.5rem;
            }

            p {
                text-align: left;
                color: #2c3e50;
            }
        }

        .card-footer {
            display: flex;
            justify-content: space-between;
            background-color: white;

            .footerWhyUs {
                display: flex;

                img {
                    border-radius: 50%;
                    width: 3vw;
                    height: 5vh;
                    margin-right: 0.5rem;

                    @media screen and (max-width: 800px) {
                        width: 8vw;
                    }
                }

            }

            p {
                margin-top: 0.5rem;
                align-self: center;
                display: block;

                @media screen and (max-width: 800px) {
                    display: flex;
                }
            }
        }
    }
}