.featuredProperties {
    // overflow: hidden;
    padding-bottom: 3rem;
    padding-top: 0rem;

    @media screen and (max-width: 650px) {
        padding-top: 5rem;
    }

    .row {
        margin-left: 2rem;
    }

    h2 {
        padding-top: 3rem;
        font-weight: bold;
    }

    .col {
        width: auto;
    }

    .card {
        margin-inline: 0.5rem;
        border-radius: 15px;
        max-width: inherit;
        position: relative;
        margin-bottom: 1rem;

        @media screen and (max-width: 650px) {
            width: 85%;
        }

        .card-body {
            text-align: left;

            .propertyImage {
                width: 100%;
                height: 30vh;
                border-radius: 10px;
                object-fit: cover;
                position: relative;
            }

            .propertyImage::after {
                content: "";
                background-color: rgba(29, 41, 62, 0.6);
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                width: 100%;
            }

            h6 {
                color: #F9C81A;
                margin-top: 0.5rem;
                font-weight: bold;
                font-size: 0.8rem;
            }

            .userImage {
                border-radius: 50%;
                height: 6vh;
                width: 3vw;
            }

            .topFeaturedButtons {
                position: absolute;
                top: 5%;

                .topFeaturedProperties {
                    background: #006132;
                    // background: #3e4c66;

                    left: 10%;
                    color: white;
                    margin-inline: 0.8rem;
                    padding: 0.2rem;
                    border-radius: 2px;
                    font-size: 0.7rem;
                }

                .topRentProperties {
                    background: #F9C81A;
                    left: 20%;
                    color: white;
                    border-radius: 2px;
                    padding: 0.2rem;
                    font-size: 0.7rem;
                }
            }

            .topFeaturedButtonsLast {
                position: absolute;
                top: 5%;
                right: 5%;

                .topFeaturedProperties {
                    background: #006132;
                    // background: #3e4c66;

                    left: 10%;
                    color: white;
                    margin-inline: 0.8rem;
                    padding: 0.2rem;
                    border-radius: 2px;
                    font-size: 0.7rem;
                }

                .topRentProperties {
                    background: #F9C81A;
                    left: 20%;
                    margin-left: 0.8rem;
                    color: white;
                    border-radius: 2px;
                    padding: 0.2rem;
                    font-size: 0.7rem;
                }
            }

            .textRentProperty {
                position: absolute;
                top: 14%;
                // left: 5%;
                font-size: 1.5rem;
                text-shadow: 5px 0px 3px black;
                color: white;
                font-weight: bold;
                width: 93.5%;
                border-radius: 0px 10px 10px 0px;
                height: 26vh;
                padding-top: 6rem;
                padding-left: 0.5rem;
                background: linear-gradient(180deg, hsla(0, 0%, 100%, .01), #1d293e);

                @media screen and (max-width: 800px) {
                    width: 93.5%;
                    padding-top: 190px;

                }
                @media screen and (max-width: 700px) {
                    width: 93.5%;
                    padding-top: 30%;

                }
                @media screen and (max-width: 600px) {
                    width: 93.5%;
                    padding-top: 45%;

                }
                @media screen and (max-width: 400px) {
                    width: 93.5%;
                    padding-top: 65%;

                }
                @media screen and (max-width: 300px) {
                    width: 93.5%;
                    padding-top: 80px;

                }
            
            }

        }

    }

    .viewALL {
        font-size: 1rem;
        font-weight: bolder;
        color: #006132;
    }
}