.services {
  .topServices {
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: 55vh;

    .h2,
    h2 {
      font-size: 2rem;
      position: relative;
      top: 50%;
      color: #006132;
      font-weight: bold;

      @media screen and (max-width: 650px) {
        top: 25%;
      }
    }
  }

  .rowCardServices.row {
    padding: 2rem;

    .card {
      // width: 35vw;
      // height: 64vh;
      // background: #dad6d65e;
      // border-radius: 15px;
      // padding: 5rem;
      // text-align: left;
      // margin-bottom: 0;
      width: 35vw;
      height: 40vh;
      background: rgba(218, 214, 214, 0.368627451);
      border-radius: 15px;
      padding: 2rem;
      text-align: left;
      margin-bottom: 0;

      @media screen and (max-width: 650px) {
        width: auto;
        margin-bottom: 1.5rem;
        padding: 1rem;
        height: auto;
      }

      h2 {
        color: #e6b800;
        font-size: 1.5rem;
      }

      p {
        color: #006132;
        font-size: 0.9rem;
      }

      button {
        background-color: transparent;
        border-color: white;
        color: #006132;
        width: 10vw;
        font-size: 0.8rem;

        @media screen and (max-width: 650px) {
          width: auto;
        }
      }
    }
  }

  .bottomCardInfo {
    padding: 2rem;
    padding-inline: 6rem;

    @media screen and (max-width: 650px) {
      padding-inline: 2rem;


    }

    .card {
      height: 40vh;
      width: 20vw;
      margin-bottom: 0rem;

      @media screen and (max-width: 650px) {
        width: auto;
        height: auto;
        margin-bottom: 3rem;

      }

      i {
        font-size: 4rem;
        margin-top: -2.5rem;
        color: #F9C81A;
        background: white;
        border-radius: 50%;
        width: 20%;
        align-self: center;
      }

      img {
        font-size: 4rem;
        margin-top: -2.5rem;
        color: #F9C81A;
        background: white;
        border-radius: 0;
        width: 30%;
        align-self: center;
      }

      div {
        padding: 2rem;
        color: #006132;

        @media screen and (max-width: 650px) {
          padding: 0rem;

        }

        span {
          font-weight: bold;
        }

        p {
          font-size: 0.8rem;
        }
      }
    }
  }

  element.style {}
}