body {
  margin: 0;
  font-family: "Axiforma", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:"Axiforma", sans-serif !important;
}
:root{
  --primary:#F9C81A,
  --secondary:#006132,
}