.responsive-menu {
  // position: fixed;
  // top:0;
}

.leftSideBar {
  display: flex;

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.loginAirbnb {
  @media screen and (max-width: 600px) {
    display: block;
    width: 20%;
    place-self: center;
  }
}

.topNavbarNavigation {
  @media screen and (max-width: 600px) {
    // display: flow !important;
    display: block !important;

    .navbar-brand {
      img {
        width: 75%;
      }
    }
  }
}

.navbarDisplay {
  background-color: transparent !important;
  color: white;
  width: 100%;
  zoom: 1;

  a.custom-link.nav-link {
    color: #F9C81A;
    text-transform: uppercase;
    font-size: 20px;
  }
}

.custom-link {
  color: white;
  font-weight: bold;

  .imageLogoDisplayWhite {
    display: inherit !important;
    margin: 0 auto;
    width: 100%;
    height: 10vh;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 0px 0px 10px 10px;
  }

  .imageLogoDisplay {
    display: none;
  }
}

// .active{
//   color: #006132;
// }

.custom-link:hover {
  color: #006132;
}

.custom-link:active {
  color: #006132;
}

.loginForm {
  h5 {
    padding: 0.2rem;
  }

  label {
    color: gray;
    position: absolute;
    top: 21%;
    left: 15.5%;
    font-size: 12px;
    margin-top: 0rem;
  }
}

.loginText {
  left: 35%;
  position: absolute;
  top: 5%;
}

.loginAirbnb {
  .loginIcon {
    background-color: white;
    // background-color: #006132;
    color: #F9C81A;
    padding-right: 0px;
    border: none;
    display: flex;
    align-items: center;
    border-radius: 20px;

    @media screen and (max-width: 600px) {
      height: fit-content;
      width: fit-content;
      position: relative;
      display: flex;
      margin: 0 auto;
    }

    span.bi.bi-list.me-2 {
      @media screen and (max-width: 600px) {
        margin-left: 1rem;
      }
    }
  }

  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: transparent;
  }
}

img.imageLogoDisplay {
  width: 120%;
  height: 15vh;
  margin-top: -1.1rem;
}