.properties_cities {
    .row {
        padding: 1.5rem;
        margin-inline: 3rem;
        // margin-inline: 6rem;

        @media screen and (max-width: 800px) {
          margin-inline: 0rem;
    
        }
        .columCities {
            overflow: hidden;
            transition: transform 0.3s ease-in-out;
            border: none;
            background-size: cover;
            background-position: center;
            margin-inline: 0.8rem;
            @media screen and (max-width: 800px) {
              margin-bottom: 1rem;
              width: inherit;
            }
        }

        .image_col {
            transform-origin: center;

            .card.cities_card {
                background: linear-gradient(180deg, hsla(0, 0%, 100%, .01), #1d293e);
                border: none;
                color: white;
                bottom: -60%;
                width: 100%;
                height: 16vh;
                border-radius: 10px;

                h3 {
                    font-weight: bold;
                    text-shadow: 1px 2px 4px black;
                    text-transform: capitalize;
                }

                p {
                    text-shadow: 1px 2px 4px black;

                }

            }
        }

        .image_container {
            background-size: cover;
            background-position: center;
            transition: transform 0.3s ease-in-out;
            height: 40vh;
            border-radius: 10px;
            margin-inline: 0.7rem;
        }

        .columCities:hover .image_container {
            transform: scale(1.1) rotate(-1deg);
        }


        // .col {
        // border: none;
        // background-size: cover;
        // background-position: center;
        // margin-inline: 0.8rem;

        // .card.cities_card {
        //     background: linear-gradient(180deg, hsla(0, 0%, 100%, .01), #1d293e);
        //     border: none;
        //     color: white;
        //     bottom: -60%;
        //     width: 13.7vw;
        //     height: 16vh;
        //     border-radius: 10px;
        //     h3{
        //         font-weight: bold;
        //         text-shadow: 1px 2px 4px black;
        //     }
        //     p{
        //         text-shadow: 1px 2px 4px black;

        //     }

        // }

        //     .image_col {
        //         height: 40vh;
        //         border-radius: 10px;

        //     }

        //     .image_col:hover {
        //         transform: scale(1.1) rotate(-1deg);
        //     }
        // }
    }


    // h2 {
    //     margin-top: 2rem;
    // }

    // .row {
    //     margin-bottom: 2rem;

    // }

    // img:hover {
    //     content: "";
    //     background-color: rgba(29, 41, 62, 0.6);
    //     // position: absolute;
    //     bottom: 0;  
    //     left: 0;
    //     right: 0;
    //     top: 0;
    //     width: 100%;
    // }

    // .bottomImageDisplay {
    //     margin: 0 auto;
    //     position: revert;
    //     text-align-last: center;

    //     .imageText {
    //         margin-top: -4rem;
    //         text-align: left;
    //         color: white;
    //         margin-left: 0;
    //         text-shadow: 5px 5px 6px black;
    //         font-weight: bold;
    //         font-size: 1.8rem;
    //     }

    //     .imageTextP {
    //         color: white;
    //         font-weight: bold;
    //         font-size: 0.8rem;
    //         text-align: left;
    //         text-shadow: 5px 5px 6px black;
    //         margin-left: 0;

    //     }
    // }

    // .imageText {
    //     margin-top: -4rem;
    //     text-align: left;
    //     color: white;
    //     margin-left: 8.5rem;
    //     text-shadow: 5px 5px 6px black;
    //     font-weight: bold;
    //     font-size: 1.8rem;
    // }


}

// .imageText {
//     color: white;
//     font-weight: bold;
//     text-align: left;
//     position: fixed;
//     top: 50%;
//     left: 25%;
//     font-size: 2rem;
//     text-shadow: 5px 5px 6px black;
//     margin-left: 1.5rem;

// }
// .imageTextPartTwo {
//     color: white;
//     font-weight: bold;
//     text-align: left;
//     position: fixed;
//     top: 60%;
//     left: 23%;
//     font-size: 2rem;
//     text-shadow: 5px 5px 6px black;
//     margin-left: 1.5rem;

// }

/* Add this CSS to your stylesheet or a style tag in your component */
.bottomImageDisplay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; /* Optional: Center text horizontally */
  }
  
  .imageText {
    color: #F9C81A; /* Optional: Set the text color */
    font-weight: bold; /* Optional: Set font weight */
    font-size: 24px; /* Optional: Set font size */
    text-shadow: 4px 1px 3px black;
  }
  
  /* Add this CSS to your stylesheet or a style tag in your component */
  .image-cities-container {
    position: relative;
    overflow: hidden;
  
    img {
      width: -webkit-fill-available;
      height: 55vh;
      margin-left: 0rem;
      object-fit: cover;
      margin-bottom: 0;
  
      @media screen and (max-width: 800px) {
        margin-left: 0rem;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
  
  .image-cities-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0%;
    width: 70vw;
    // margin-left: 7rem;
    margin-left: 1.8rem;

    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
  
    /* Add a smooth transition effect */
    @media screen and (max-width: 800px) {
      margin-left: 0rem;
      width: 100%;
      height: 95%;
    }
  }
  
  .image-cities-container:hover::before {
    opacity: 1; /* Show the overlay on hover */
  }
  

//   second container
.image-cities-containertwo {
  position: relative;
  overflow: hidden;

  img {
    width: -webkit-fill-available;
    height: 55vh;
    margin-left: -5rem;
    object-fit: cover;

    @media screen and (max-width: 800px) {
      margin-left: 0rem;
      width: 100%;

    }
  }
}

.image-cities-containertwo::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0%;
  width: 34.5vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Adjust the opacity here */
  opacity: 0;
  /* Initially hidden */
  transition: opacity 0.3s ease;

  /* Add a smooth transition effect */
  @media screen and (max-width: 800px) {
    margin-left: 0rem;
    width: 100%;
    height: 95%;

  }
}
  
  .image-cities-containertwo:hover::before {
    opacity: 1; /* Show the overlay on hover */
  }

  //   third container
  .image-full {
    position: relative;
    overflow: hidden;
    margin-top: 2rem;
    margin-bottom: 2rem;
    img{
      width:110.5vw;
      height:55vh;
      margin-left: 0rem;
      object-fit: cover;
      @media screen and (max-width: 800px) {
        margin-left: 0rem;
        width: 100%;
  
      }
    }
  }
  
  .image-full::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0%;
    // margin-left: 9.8rem;
    margin-left: 0.5rem;

    width: 110vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
    @media screen and (max-width: 800px) {
      margin-left: 0rem;
      width: 132vw;

  }
  }
  
  .image-full:hover::before {
    opacity: 1; /* Show the overlay on hover */
  }