.propertyAsset {
  padding: 4rem;
  @media screen and (max-width: 650px) {
    padding: 1rem;
}

  .topasset {
    h2 {
      color: #006132;
      
    }

    p {
      padding-inline: 15rem;
      color: gray;
      font-size: 1.3rem;
      line-height: 2;
      margin-bottom: 3rem;
      @media screen and (max-width: 650px) {
        padding-inline:1rem;
        text-align: left;
    }
    }
  }

  .row {
    i {
      padding: 2rem;
      border-radius: 50%;
      border: 1px solid #006132;
      font-size: 3rem;
      color: #006132;
      &:hover {
        background-color: #e6b800;
        cursor: pointer;
        color: white;
        border: 1px solid #e6b800;
        padding: 2.5rem;
      }
    }
    h2 {
      margin-top: 2rem;
      color: #006132;
      font-size: 1rem;
      line-height: 3;
      &:hover {
        color: #e6b800;
      }
    }
    button {
      background-color: transparent !important;
      border-color: #006132;
      color: #006132;
      margin-bottom: 5rem;
      &:hover {
        color: white;
        background-color: #e6b800 !important;
        border-color: #e6b800;
      }
    }
    .custom-col {
      &:hover {
        i {
          background-color: #e6b800;
          cursor: pointer;
          color: white;
          border: 1px solid #e6b800;
          padding: 2.5rem;
        }
        h2 {
          color: #e6b800;
        }
        button {
          color: white;
          background-color: #e6b800 !important;
          border-color: #e6b800;
        }
      }
    }
  }
}
