.real-agent{
    background-color: #006132;
    color: white;
    display: flex;
    justify-content: space-around;
    padding: 40px;

    button{
        background-color: #F9C81A;
        border: 1px solid #F9C81A;
        height: 8vh;
    }
}