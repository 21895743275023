// .SideBar {
//     position: fixed;
//     margin-top: 3rem;
//     /* z-index: 10; */
//     height: 100vh;
//     // background: white;
//     width: 19%;
//     border-right: 1px solid lightgray;

//     @media screen and (max-width: 576px) {
//         height: 100%;
//     }
// }

// /* the design for the side bar that contains the navigations */
// .sidebar {
//     position: fixed;
//     top: 0px;
//     margin-top: 4rem;
//     // left: -250px;
//     height: 100%;
//     // border-right: 1px solid lightgray;
//     width: 250px;
//     z-index: 1;
//     overflow-y: scroll;
//     padding: 20px;
//     transition: left 0.3s ease-in-out;
//     overflow-x: hidden;

//     .slider-container {
//         margin-top: 2rem;
//         padding: 0rem;
//         width: 14vw;
//         /* background: red; */
//         z-index: 1020;
//         position: fixed;
//     }

//     .input-range__label--max .input-range__label-container {
//         left: 25%;
//     }

//     .input-range__label-container {
//         left: -30%;
//         position: relative;
//     }

//     .rent_range {

//         margin-top: 1rem;
//         left: -27%;
//         color: gray;
//         position: relative;

//     }

//     @media screen and (min-width: 768px) {
//         z-index: 10;
//         //   background-color: white;
//     }

//     @media screen and (max-width: 576px) {
//         position: relative;
//     }

//     select.form-select {
//         color: gray;
//         width: 14vw;
//         margin-top: 1rem;

//     }

// }

// .sidebar.show {
//     left: 0;
// }

// /* the design that contains the kodi logo on the side bar */
// // .navbar {
// //     background-color: white;
// //     width: 250px;
// //     place-content: center;
// //     // border-right: 1px solid lightgray;
// //     position: fixed;

// //     @media screen and (max-width: 576px) {
// //         width: 65% !important;
// //         overflow-x: hidden;

// //     }

// // }

// .navbar-brand {
//     font-weight: bold;
//     font-size: 22px;
//     color: #006132;
//     margin: 0 auto;
//     text-transform: uppercase;
//     // font-family: 'Fonarto', sans-serif;

// }

// .navbar-text {
//     font-size: 1rem;
// }

// select option:hover {
//     background-color: yellow;
// }



// .nav-link {
//     font-size: 16px;
//     color: #343a40;
//     text-align: center;
//     border-radius: 0.25rem;
//     padding: 0.5rem 1rem;
//     margin-bottom: 0.5rem;
//     display: flex;
// }

// .nav-link i {
//     margin-right: 0.7rem;
// }

// // .nav-link:hover {
// //     background-color: #e9ecef;
// //     color: #343a40;
// //     border-radius: 9px;
// // }

// // .nav-link.active {
// //     background-color: #e9ecef;
// //     color: #343a40;
// //     border-radius: 9px;
// // }

// /* width */
// ::-webkit-scrollbar {
//     width: 8px;
// }

// /* Track */
// ::-webkit-scrollbar-track {

//     border-radius: 1px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//     background: lightgray;
//     border-radius: 1px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//     background: lightgray;
// }


// button.btn.sidebar-toggle.navbar-toggler-icon {
//     display: none !important;

//     @media only screen and (max-width: 768px) {
//         display: block !important;
//     }
// }

// /* media queries */
// @media only screen and (max-width: 768px) {


//     .navbar {
//         width: 100%;
//         justify-content: left;
//         position: absolute;
//         margin-bottom: 1rem;
//         margin-top: 3.5rem;
//         z-index: 1050;
//         margin-left: 0 !important;
//         background: white;
//         border-right: 0px solid lightgray;
//     }

//     .sidebar {
//         margin-top: 7rem;
//         left: -250px;
//         height: 100%;
//         border-right: 0px solid lightgray;
//         width: 250px;
//         background-color: white;
//         z-index: 1020;
//         overflow-y: scroll;
//         padding: 20px;
//         transition: left 0.3s ease-in-out;
//         overflow-x: hidden;

//         @media (max-width:576px) {

//             height: 302vh;
//         }
//     }
// }

// @media (max-width:600) {
//     .sidebar {
//         width: 250px;
//         z-index: 1020;
//         background-color: white;
//     }

//     .navbar button.btn.sidebar-toggle {
//         display: block;
//         background: blue;
//         width: 10rem;
//         height: 10rem;
//         border: none;
//     }

// }

// @media (min-width:600) {

//     .sidebar-toggle {
//         position: absolute;
//         left: 0;
//         width: 50px;
//         height: 50px;
//         border: none;
//         display: none !important;
//     }
// }

// .input-range__track--active {
//     background: #F9C81A;
// }

// .input-range__slider {
//     appearance: none;
//     background: #F9C81A;
//     border: 1px solid #F9C81A;
//     border-radius: 100%;
//     cursor: pointer;
//     display: block;
//     height: 1rem;
//     margin-left: -0.5rem;
//     margin-top: -0.65rem;
//     outline: none;
//     position: absolute;
//     top: 50%;
//     transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
//     width: 1rem;
// }


// filter display
.SideBar {
    border-top: 1px solid lightgray;
    margin-top: 6rem;
    display: flex;
    .sidebar {
        display: flex;
        justify-content: space-between;
    }
}